






import { Component, Prop, Vue } from 'vue-property-decorator'
import { themeConfig } from '@/config/themeConfig'

@Component
export default class Logo extends Vue {
  @Prop() type
  @Prop() color
  @Prop() height
  @Prop() width

  wideLogo = themeConfig.app.wideLogo
}
